import React, { useEffect, useMemo, useState } from "react";
import "./guess-price-input.scss";
import PropTypes from "prop-types";
import Inputmask from "inputmask";
import { Tooltip } from "react-tooltip";

import { isMobile } from "react-device-detect";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useAuth0 } from "@auth0/auth0-react";
import { isMobileSafari } from "react-device-detect";
import CustomInput from "../custom-input/custom-input";
import { isNil } from "lodash";
import clsx from "clsx";
import { formatPrice } from "../../../helpers/helpers";

const INPUTMASK_CONFIG = {
    alias: "numeric",
    allowMinus: false, // disables negative values
    rightAlign: false,
    groupSeparator: ",",
    autoGroup: true,
    digits: 0,
    radixPoint: "",
    prefix: "$",
    max: "999999999999999", // Max 15 digits excluding prefix and separator
    onBeforeWrite: function (event, buffer, caretPos, opts) {
        // Example buffer value: ['0', '0', '0', ',', '1', '$'] = $1,000
        // If the last 3 digit is zero then position caret in between the thousand and separator (comma)
        const isLast3DigitsZero = buffer.slice(0, 3).every((item) => item === "0");

        return { caret: isLast3DigitsZero && buffer.length >= 6 ? 4 : caretPos };
    },
};

const TOOLTIP_TIMEOUT = 5000;

const GuessPriceInput = ({
    inputRef,
    inputDisable,
    handleGuessPriceChange,
    prices,
    onClear,
    score,
    isChallengeMode,
    hasGuessedAllProperties,
}) => {
    const { isAuthenticated } = useAuth0();
    const showTip = localStorage.getItem("guess_hint_tip") ?? null;
    const [hasSeenTip, setHasSeenTip] = useState(!!showTip);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);
    const [dirty, setDirty] = useState(false);

    const setTipStateInLocalStorage = (value) => localStorage.setItem("guess_hint_tip", value);

    // Add a numeric input mask to the guess price input
    // https://app.asana.com/0/1204535088114623/1204866711732736/f
    useEffect(() => {
        if (!inputDisable) {
            const inputMask = Inputmask(INPUTMASK_CONFIG);

            inputMask.mask("guess-price");
        }
    }, [inputDisable]);

    // Open tooltip if not seen else close after 10s
    useEffect(() => {
        if (!isAuthenticated) {
            if (!openTooltip && !hasSeenTip) {
                const timeout = setTimeout(() => {
                    setOpenTooltip(true);
                    setTipStateInLocalStorage(new Date());
                }, TOOLTIP_TIMEOUT);

                return () => {
                    clearTimeout(timeout);
                };
            } else {
                const timeout = setTimeout(() => {
                    setOpenTooltip(false);
                    setHasSeenTip(true);
                    setTipStateInLocalStorage(new Date());
                }, TOOLTIP_TIMEOUT);

                return () => {
                    clearTimeout(timeout);
                };
            }
        }
    }, [openTooltip, hasSeenTip, isAuthenticated]);

    // Close tooltip if user has inputted a guess or if it has been seen
    useEffect(() => {
        if (!isAuthenticated) {
            if (!!prices.guess || hasSeenTip) {
                setOpenTooltip(false);
                setHasSeenTip(true);
                setTipStateInLocalStorage(new Date());
            }
        }
    }, [prices.guess, hasSeenTip, isAuthenticated]);

    // Toggle the scrolling if the guess input is focused or blurred
    useEffect(() => {
        if (inputFocused) {
            document.body.style.overflow = "hidden";

            if (isMobileSafari) {
                document.body.style.touchAction = "none";
            }
        } else {
            document.body.style.overflow = "";

            if (isMobileSafari) {
                document.body.style.touchAction = "";
            }
        }
    }, [inputFocused]);

    const handleOnChange = (e) => {
        const newValue = e.target.value;

        if (!dirty) {
            handleGuessPriceChange({ target: { value: `${newValue}000` } });
        } else {
            handleGuessPriceChange(e);
        }

        if (!newValue || !newValue.length) {
            setDirty(false);
        } else {
            setDirty(true);
        }
    };

    const handleOnClear = () => {
        onClear();
        const currentElement = inputRef?.current;

        if (currentElement) {
            currentElement.value = "";
            const event = new Event("input", { bubbles: true });

            // Force and onchange event to fix bug during clearing value
            currentElement.dispatchEvent(event);
        }

        setDirty(false);
    };

    const guessPrice = useMemo(() => formatPrice(prices.guess), [prices.guess]);
    const actualPrice = useMemo(() => formatPrice(prices.actual), [prices.actual]);

    return (
        <div id="guess-price-container">
            {!inputDisable ? (
                <>
                    <CustomInput
                        onClear={handleOnClear}
                        data-tooltip-id="guess-price-tooltip"
                        className={clsx(Number(prices?.guess) > 0 && "gp-input-updating")}
                        data-cy="guess-price"
                        type="text"
                        id="guess-price"
                        placeholder="Guess the sold price"
                        inputRef={inputRef}
                        value={prices.guess}
                        onChange={handleOnChange}
                        disabled={inputDisable}
                        tabIndex={0}
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                    />

                    {!hasSeenTip && !isAuthenticated && !isChallengeMode && (
                        <Tooltip
                            place="top"
                            id="guess-price-tooltip"
                            clickable
                            render={() => (
                                <div className="content">
                                    <button
                                        onClick={() => {
                                            setTipStateInLocalStorage(new Date());
                                            setHasSeenTip(true);
                                        }}
                                        className="close-btn"
                                    >
                                        <IoCloseCircleOutline fontSize={17} />
                                    </button>
                                    <p className="mb-0">Psst: want a hint?</p>
                                    <p className="mb-0">Start typing your guess</p>
                                </div>
                            )}
                            isOpen={openTooltip}
                        />
                    )}
                </>
            ) : (
                <div
                    id="guess-price-input-disabled"
                    className={clsx(
                        "position-relative",
                        hasGuessedAllProperties && isMobile && "m-has-guessed-all"
                    )}
                >
                    <div className={clsx("d-flex", hasGuessedAllProperties && isMobile && "flex-column")}>
                        <span className="user-guess">{guessPrice}</span>
                        <span className="actual-price">{actualPrice}</span>
                    </div>

                    {!isNil(score) ? (
                        <span className="guess-score position-absolute">+{score || 0}pts</span>
                    ) : null}
                </div>
            )}
        </div>
    );
};

GuessPriceInput.propTypes = {
    inputDisable: PropTypes.bool.isRequired,
    handleGuessPriceChange: PropTypes.func.isRequired,
    inputRef: PropTypes.object.isRequired,
    prices: PropTypes.exact({
        guess: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        actual: PropTypes.number.isRequired,
    }).isRequired,
    onClear: PropTypes.func.isRequired,
    score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isChallengeMode: PropTypes.bool,
    hasGuessedAllProperties: PropTypes.bool,
};

export default GuessPriceInput;
