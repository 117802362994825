module.exports = {
    PLUS_MINUS_VERSIONS: {
        "v1.0.0": {
            variance: {
                start: 0.7,
                end: 0.9,
            },
        },
        "v1.0.1": {
            variance: {
                start: 0.6,
                end: 0.7,
            },
        },
        "v1.1.0": {
            variance: {
                start: 0.6,
                end: 0.7,
            },
            max_score: 7,
        },
        "v2.0.0": {
            variance: {
                start: 0.65,
                end: 0.75,
            },
            max_score: 7,
            modal: {
                price_hidden: true,
            },
            has_next_button: true,
        },
        "v2.1.0": {
            variance: {
                start: 0.65,
                end: 0.75,
            },
            min_variance: {
                start: 0.03,
                end: 0.05,
            },
            modal: {
                price_hidden: true,
            },
            has_next_button: true,
            unlimited_score: true,
        },
    },
};
