var priceTagIcon = require("../assets/icons-v2/price-tag-icon.png");
var challengedIcon = require("../assets/icons-v2/challenged.png");
var unlockIcon = require("../assets/icons-v2/padlock.png");
var graphIcon = require("../assets/icons-v2/ranking-graph-icon.png");

module.exports = {
    STARTUP_MODAL_CONTENTS: [
        {
            header: "Let’s get started",
            text: "Choose your location and guess the price of your first 3 properties.",
            icon: priceTagIcon,
            alt: "price-tag-icon",
        },
        {
            header: "Challenge a friend",
            text: `After each property, challenge friends to beat your score. Tap to share by text, DM, or to the <a href="https://www.facebook.com/groups/pricemegame" style="color: var(--meteorite);" title="Priceme Facebook Group" target="_blank" rel="noreferrer">Facebook group.</a>`,
            icon: challengedIcon,
            alt: "challenged-icon",
        },
        {
            header: "Unlock all 6 Daily Properties",
            text: "Whoever wins the challenge unlocks one of their remaining properties. Unlock all 6 for a complete game.",
            icon: unlockIcon,
            alt: "unlock-icon",
        },
        {
            header: "See where you rank",
            text: "Don’t forget to check the leaderboard. Every day is a new chance to improve your score.",
            icon: graphIcon,
            alt: "graph-icon",
        },
    ],
};
