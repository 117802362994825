import React, { useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { find, get } from "lodash";

import CustomDropdown from "../../../../components/common/custom-dropdown/custom-dropdown";
import useDropdownDisclosure from "../../../../components/common/custom-dropdown/hooks/useDropdownDisclosure";
import CustomSvg from "../../../../components/common/custom-svg/custom-svg";
import WinnerIcon from "../../../../assets/icons-v2/result-popup/party.png";
import LoserIcon from "../../../../assets/icons-v2/challenge-lost.png";
import TiedIcon from "../../../../assets/icons-v2/challenge-tied.png";

import { useUser } from "../../../../context/UserContext";
import { getUserChallengesHistory } from "../../../../services/UserService";
import "./challenges-history.scss";

/**
 * ChallengesHistory component displays the history of challenges for a user.
 */
const ChallengesHistory = () => {
    const { userState, setUserState } = useUser();
    const { dropdownRef, openDropdown, handleOpen } = useDropdownDisclosure();
    const [selected, setSelected] = useState({ name: "All Players (0-0-0)", id: 0 });
    const [opponents, setOpponents] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [filteredChallenges, setFilteredChallenges] = useState([]);
    const challengeHistorySelectedUser = get(userState, "challenge_history_selected_user", {
        id: null,
        nickname: "",
    });

    const userChallengesHistory = useQuery({
        queryKey: ["user_challenges_history", userState?.id],
        queryFn: () => getUserChallengesHistory(userState?.id),
        placeholderData: [],
    });

    const userChallengesHistoryData = get(userChallengesHistory, "data.data.userChallengesHistory", []);

    const isLoading = userChallengesHistory?.isLoading || userChallengesHistory.isFetching;

    useEffect(() => {
        if (userChallengesHistoryData.length) {
            processFetchedData(userChallengesHistoryData);
        }
    }, [userChallengesHistoryData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            challengeHistorySelectedUser?.id &&
            selected?.id !== challengeHistorySelectedUser?.id &&
            opponents.length
        ) {
            const currentUserSelected = find(opponents, { id: challengeHistorySelectedUser?.id });
            handleSelect(currentUserSelected, true);
        }
    }, [challengeHistorySelectedUser, opponents]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Processes the fetched data to extract opponents and challenges information.
     * @param {Array} fetchedData - The data fetched from the server.
     */
    const processFetchedData = (fetchedData) => {
        const opponentsMap = new Map();
        const processedChallenges = [];
        let totalWins = 0;
        let totalLosses = 0;
        let totalTies = 0;

        fetchedData.forEach((challenge) => {
            const score = { points: challenge.current_user_points };
            const opponentScore = { points: challenge.opponent_points };
            const opponent = {
                id: challenge.opponent_id,
                nickname: challenge.opponent_nickname,
            };

            // Process opponents
            if (opponent) {
                if (!opponentsMap.has(opponent.id)) {
                    opponentsMap.set(opponent.id, {
                        id: opponent.id,
                        name: opponent.nickname,
                        nickname: opponent.nickname,
                        wins: 0,
                        losses: 0,
                        ties: 0,
                    });
                }

                const opponentData = opponentsMap.get(opponent.id);
                if (score.points > opponentScore.points) {
                    opponentData.losses++;
                    totalWins++;
                } else if (score.points < opponentScore.points) {
                    opponentData.wins++;
                    totalLosses++;
                } else {
                    opponentData.ties++;
                    totalTies++;
                }
                opponentsMap.set(opponent.id, opponentData);
            }

            // Process challenges
            processedChallenges.push({
                ...challenge,
                challenged_property_id: challenge.Property.id,
                challenged_property_address: challenge.Property.street_address?.split(",")[0],
                challenged_property_neighborhood: challenge.Property.neighborhood,
            });
        });

        // Convert opponentsMap to array
        const opponentsArray = Array.from(opponentsMap.values()).map((opponent) => ({
            ...opponent,
            name: `${opponent.name} (${opponent.losses}-${opponent.wins}-${opponent.ties})`,
        }));

        // Sort by total number of challenges (most to least)
        opponentsArray.sort((a, b) => {
            const totalChallengesA = a.losses + a.wins + a.ties;
            const totalChallengesB = b.losses + b.wins + b.ties;
            return totalChallengesB - totalChallengesA;
        });

        // Add 'All Players' option with total wins, losses, and ties
        opponentsArray.unshift({ name: `All Players (${totalWins}-${totalLosses}-${totalTies})`, id: 0 });

        setOpponents(opponentsArray);
        setChallenges(processedChallenges);

        if (!challengeHistorySelectedUser?.id) {
            setSelected({ name: `All Players (${totalWins}-${totalLosses}-${totalTies})`, id: 0 }); // Set initial state with totals
        }
        setFilteredChallenges(processedChallenges);
    };

    /**
     * Filters the challenges based on the selected opponent.
     * @param {Object} opponent - The selected opponent.
     * @param {Boolean} isSetOutside - Identifier if the method was called in the dropdown or value was set somewhere else.
     */
    const handleSelect = (opponent, isSetOutside = false) => {
        setSelected(opponent);
        if (opponent?.id === 0) {
            setFilteredChallenges(challenges);
        } else {
            setFilteredChallenges(challenges.filter((challenge) => challenge.opponent_id === opponent?.id));
        }

        // Prevent infinite loop
        if (!isSetOutside) {
            setUserState({
                ...userState,
                challenge_history_selected_user: { id: opponent.id, nickname: opponent.nickname },
            });
            handleOpen();
        }
    };

    const handleSendChallenge = () => {
        setUserState({ ...userState, show_scrollability_tooltip: true });
        window.location.href = "/daily-properties";
    };

    const sortedChallenges = filteredChallenges.slice().sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
    });

    return (
        <div className="challenges-history-container">
            <div>Select opponent:</div>
            <div className="user-challenges-dropdown">
                <CustomDropdown
                    dropdownRef={dropdownRef}
                    open={openDropdown}
                    selected={selected?.name}
                    handleOpen={handleOpen}
                    items={opponents}
                    handleSelect={handleSelect}
                    fullWidth
                    textAlign="left"
                />
            </div>
            {sortedChallenges.length ? (
                sortedChallenges.map((challenge, index) => {
                    const {
                        type,
                        date,
                        challenged_property_address,
                        challenged_property_neighborhood,
                        current_user_nickname,
                        current_user_points,
                        opponent_nickname,
                        opponent_points,
                    } = challenge;

                    let challengeIcon = WinnerIcon;

                    if (current_user_points === opponent_points) {
                        challengeIcon = TiedIcon;
                    } else if (current_user_points < opponent_points) {
                        challengeIcon = LoserIcon;
                    }

                    return (
                        <div key={index} className="challenges-history-item-container my-3">
                            <span className={clsx("ch-item-date", type === "sent" && "sent")}>
                                {moment(date).tz("America/Chicago").format("MM/DD/YY")}
                            </span>
                            <div className="d-flex gap-2">
                                <CustomSvg src={challengeIcon} size={{ height: 35, width: 35 }} />
                                <div>
                                    <div className="property-location">
                                        {challenged_property_address} | {challenged_property_neighborhood}
                                    </div>
                                    <div className="item-players">
                                        {current_user_nickname} ({current_user_points}) vs {opponent_nickname}{" "}
                                        ({opponent_points})
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : isLoading ? (
                <di>Loading...</di>
            ) : (
                <>
                    <div className="no-challenge-history-text">You don't have any Challenges...yet.</div>
                    <div className="no-challenge-history-text">
                        Try{" "}
                        <span className="send-challenge" onClick={handleSendChallenge}>
                            sending one
                        </span>{" "}
                        now!
                    </div>
                </>
            )}
        </div>
    );
};

export default ChallengesHistory;
