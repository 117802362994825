/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./daily-properties.scss";
import ResultsModal from "../../components/modals/results-modal/results-modal";
import FinishedModal from "../../components/modals/finished-modal/finished-modal";
import ProgressIndicator from "../../components/common/progress-indicator/progress-indicator";
import { unmaskValue, guessParser, createChallengeLink } from "../../helpers/helpers";
import Sticky from "react-sticky-el";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    getUserGuess,
    getUserGuessesPerPeriod,
    submitGuess,
    getDailyProperties,
} from "../../services/UserService";
import { useUser } from "../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import CustomButton from "../../components/common/buttons/custom-button/custom-button";
import { getAreaById } from "../../services/AreaService";
import { findLastIndex, isEmpty, isNil, isNull, max, find, get, filter, isEqual } from "lodash";
import { useScore } from "../../context/ScoreContext";
import { useGuestContext } from "../../context/GuestContext";
import { getScoreFeedback } from "../../helpers/getScoreFeedback";
import OneSignal from "react-onesignal";
import toast from "react-hot-toast";
import { TOAST_MESSAGES } from "../../lang/toast-messages.lang";
import GuessPriceInput from "../../components/common/guess-price-input/guess-price-input";
import TotalScore from "../../components/common/total-score/total-score";
import CircleLoadingProgress from "../../components/common/circle-loading-progress/circle-loading-progress";
import useGAEvent from "../../hooks/useGAEvent";
import useModalState from "../../hooks/useModalState";
import { GuestHints } from "./components";
import PracticeResultsModal from "../../components/modals/practice-results-modal/practice-results-modal";
import SoldoutModal from "../../components/modals/soldout-modal/soldout-modal";
import PropertyInfo from "../../components/common/PropertyInfo";
import CustomTooltip from "../../components/common/CustomTooltip";
import config from "../../config";
import { INIT_PROPERTY_VALUES } from "../../constants/initial-property-values";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { toastAndRedirect } from "../../helpers/toastAndRedirect";

const OPEN_GUESS_HINT_TIMEOUT = 400;

/**
 * Fetches the previously stored properties from local storage.
 *
 * @returns {Object} The parsed data from local storage, defaulting to an object with an empty `ids` array if no data is found.
 */
const fetchPreviousProperties = () => {
    const data = localStorage.getItem("previousProperties");
    return data ? JSON.parse(data) : { ids: [] };
};

/**
 * Stores the given data in local storage under the key "previousProperties".
 *
 * @param {Object} data - The data to be stored in local storage.
 */
const storePreviousProperties = (data) => {
    localStorage.setItem("previousProperties", JSON.stringify(data));
};

export default function DailyProperties(props) {
    const { userState, setUserState } = useUser();
    const [arrProperties, setArrProperties] = useState([]);
    const [property, setProperty] = useState(INIT_PROPERTY_VALUES);
    const [inputDisable, setInputDisable] = useState(false);
    const [guessPrice, setGuessPrice] = useState("");
    const [results, setResults] = useState({ score: 0, feedback: "" });
    const [showResultsModal, setShowResultsModal] = useState(false);
    const [showFinishedModal, setShowFinishedModal] = useState(false);
    const [isInitialPropertySet, setIsInitialPropertySet] = useState(false);
    const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);
    const [latestPropertyIndex, setLatestPropertyIndex] = useState(0);
    const [inputValues, setInputValues] = useState({});
    const [propertyGuessMappings, setPropertyGuessMappings] = useState([]);
    const { isAuthenticated, isLoading: isLoadingAuth } = useAuth0();
    const { addTotalScore, totalScore } = useScore();
    const { guestPlay, setGuestPlay, getGuestPlaysToday } = useGuestContext();
    const [hasPlayed, setHasPlayed] = useState(false);
    const [refetchUserGuesses, setRefetchUserGuesses] = useState(true);
    const { sendEvent } = useGAEvent();
    const { setModalId } = useModalState();
    const [showPracticeFinalScore, setShowPracticeFinalScore] = useState({ score: 0, show: false });
    const [showSoldoutModal, setShowSoldoutModal] = useState(false);
    const [openGuessHint, setOpenGuessHint] = useState(false);
    const [hasOpenedGuessHint, setHasOpenedGuessHint] = useState(false);
    const [countdown, setCountdown] = useState(10);
    const [guessTriggered, setGuessTriggered] = useState(false);
    const [currentScoreId, setCurrentScoreId] = useState(null);
    const isPreviousProperty = currentPropertyIndex < latestPropertyIndex;
    const bodyRef = useRef(null);
    const propertyAddress = property.street_address?.split(",")[0];
    const [showLoader, setShowLoader] = useState(false);
    const [userGuessesData, setUserGuessesData] = useState(null);
    const navigate = useNavigate();
    const [showUnlockPropertyToottip, setShowUnlockPropertyToottip] = useState(false);
    const [unlockedPropertyIndex, setUnlockedPropertyIndex] = useState(3); // 4th property

    const progressLength = arrProperties.length;

    const isScrollable = progressLength > 3;

    const unLockedArrProperties = arrProperties.filter((property) => !property.is_locked);
    const propertyIds = unLockedArrProperties.map((property) => property?.property_id || property?.id);
    const userGuessesArray = get(userGuessesData, "userGuesses", []);
    const guessesCount = userGuessesArray.reduce((count, guess) => {
        if (propertyIds.includes(guess?.property_id || guess?.PropertyId)) {
            return count + 1;
        }
        return count;
    }, 0);

    const unLockedPropertiesCount = unLockedArrProperties.length;
    const hasGuessedAllProperties = guessesCount === unLockedPropertiesCount;
    const hasSkippedProperties =
        currentPropertyIndex + 1 === unLockedPropertiesCount && !hasGuessedAllProperties;

    // Extracting score.points from user guesses and ordering them according to arrProperties
    const orderedScores = arrProperties
        .map((propertyItem) => {
            if (propertyItem.is_locked) return null;

            const guess = find(userGuessesArray, ["property_id", propertyItem.property_id]);
            return guess ? guess.Score.points : 0;
        })
        .filter((item) => item); // filter locked/null properties

    const currentUserPropertyId = arrProperties[currentPropertyIndex]?.id ?? null;

    let currentInputValue = isPreviousProperty ? inputValues[property.id] || guessPrice : guessPrice;
    let inputRef = useRef(null);
    let dailyScore = 0;

    const currentArea = useQuery({
        queryKey: ["current-area", userState?.current_area_id],
        queryFn: () => getAreaById(userState?.current_area_id),
        enabled: !!userState?.current_area_id,
    });

    const userGuesses = useQuery({
        queryKey: ["user-guesses", userState?.id],
        queryFn: () =>
            getUserGuessesPerPeriod({
                user_id: userState?.id,
            }),
        cacheTime: 0,
        enabled: isAuthenticated && !!userState?.id,
        placeholderData: () => {
            const guestPlayToday = getGuestPlaysToday();

            return {
                data: {
                    userGuesses: guestPlayToday,
                    count: {
                        total: isNil(guestPlayToday?.length) ? 0 : guestPlayToday?.length,
                    },
                },
            };
        },
    });

    const perPropertyScore = isAuthenticated
        ? get(find(userGuessesArray, ["Score.UserProperty.id", currentUserPropertyId]), "Score.points", 0) // Get the score based on the user property id
        : get(find(guestPlay, ["id", property.id]), "score", 0); // Get the score based on the property id

    /**
     * Determines whether the "Next Listing" button should be displayed based on the current property index, user authentication status, and input values.
     *
     * @type {boolean}
     * @description
     * This variable evaluates conditions to decide whether the "Next Listing" button should be visible.
     * If the current property index is not 2, or if it is 2 and:
     * - the user is authenticated and has provided all required input values, or
     * - the user is not authenticated and has made three guesses,
     * then the button will be shown; otherwise, it will be hidden.
     *
     */
    let showNextListingButton =
        currentPropertyIndex !== arrProperties?.length - 1 ||
        (currentPropertyIndex === arrProperties?.length - 1 &&
            ((isAuthenticated && Object.keys(inputValues).length === arrProperties?.length) ||
                (!isAuthenticated && userGuesses.data.data.count?.total === arrProperties?.length)));

    /**
     * Executes a mutation to submit a user's guess score.
     *
     * @param {object} userGuess - The user's guess data.
     * @param {number} userGuess.id - The unique identifier for the user's guess.
     * @param {number} userGuess.guess - The user's guess value.
     *
     * @returns {Promise} A promise representing the result of the mutation.
     */
    const userGuessScoreMutation = useMutation({
        mutationFn: (userGuess) => {
            return submitGuess(userState.id, property.id, userGuess, null, currentUserPropertyId);
        },
    });

    const dailyProperties = useQuery({
        queryKey: ["daily-properties", userState?.id, isAuthenticated],
        queryFn: () => {
            setShowLoader(isNil(localStorage.getItem("previousProperties")));

            return getDailyProperties(
                !isNil(userState?.id) && !isNull(userState?.id) ? userState?.id : "null",
                isAuthenticated,
                true
            );
        },
        enabled: !isLoadingAuth && (isAuthenticated ? !isNil(userState?.id) : true),
        cacheTime: 0,
        onSuccess: (data) => {
            // If the getDailyProperties query is not successful, show an error message and redirect back to home page
            if (!data?.data?.success && !isNil(data?.data?.message) && !isEmpty(data?.data?.message)) {
                localStorage.setItem("show_location_modal", true);
                toastAndRedirect("error", data.data.message, 2500, 3000);

                return;
            }

            const newData = data?.data?.properties ?? [];
            const newProperties = { ids: newData.map((data) => data?.property_id || data.id) };
            const previousProperties = fetchPreviousProperties();

            // Compare new data with previous data
            if (!isEqual(newProperties, previousProperties)) {
                setShowLoader(true); // Set loading state to true if data has changed
            } else {
                setShowLoader(false); // Set loading state to false if data is the same
            }

            // Update state with new data and store previous data for future comparisons
            setArrProperties(newData);
            storePreviousProperties(newProperties);
        },
    });

    /**
     * Fetches the current property guess for a user.
     *
     * @returns {Promise} A promise representing the user's current property guess.
     */
    const currentPropertyGuess = useQuery({
        queryKey: [
            "current_property_guess",
            property.id,
            userState?.id,
            property?.play_date,
            currentUserPropertyId,
        ],
        queryFn: () => getUserGuess(userState?.id, property.id, property?.play_date, currentUserPropertyId),
        cacheTime: 0,
        enabled: isAuthenticated && !isNil(userState?.id) && !isNil(property.id) && !isEmpty(propertyAddress),
        placeholderData: () => {
            return {
                guess: inputValues[property.id],
            };
        },
    });

    useEffect(() => {
        if (isAuthenticated) {
            const newScoreId = get(find(userGuessesArray, ["property_id", property.id]), "Score.id", null);

            // set currentScoreId to fix send challenge issue
            if (newScoreId && currentScoreId !== newScoreId) {
                setCurrentScoreId(newScoreId);
            }
        }
    }, [isAuthenticated, currentScoreId, property?.id, userGuessesArray]);

    useEffect(() => {
        if (!isLoadingAuth && !currentArea.isFetching) {
            // Send GA event when the current property changes
            if (!isNil(currentPropertyIndex)) {
                sendEvent(`property_${currentPropertyIndex + 1}_load`, {
                    isAuthenticated,
                    userArea: currentArea?.data?.name,
                });
            }
        }
    }, [isLoadingAuth, currentArea.isFetching, currentPropertyIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Disable the inputs if this is a previous property and has a current input value
        // Otherwise, enable it
        if (isPreviousProperty && currentInputValue) {
            setInputDisable(true);
        } else {
            setInputDisable(false);
        }
    }, [property.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // If the property guess query is finished loading and it has a guess price value, set the input values, guess price and disable the input
    useEffect(() => {
        if (isAuthenticated && !currentPropertyGuess.isLoading && !isNil(currentPropertyGuess?.data?.guess)) {
            setInputValues((prevState) => ({
                ...prevState,
                [property.id]: currentPropertyGuess.data.guess,
            }));
            setGuessPrice(currentPropertyGuess.data.guess);
            setInputDisable(true);
        }
    }, [currentPropertyGuess.isLoading, currentPropertyGuess.data]); // eslint-disable-line react-hooks/exhaustive-deps

    // Show the first unanswered property if there are properties left to guess. Otherwise, just show the first property
    // This will also render the property progress indicator depending on which have guess or none
    /* eslint-disable react-hooks/exhaustive-deps */
    useMemo(() => {
        function showFirstUnansweredProperty() {
            const bHasFinishedLoading =
                !dailyProperties.isFetching && !userGuesses.isFetching && !isLoadingAuth;
            const bValidData = isAuthenticated ? !userGuesses.isPlaceholderData : true;

            // Check if the daily properties, user guesses and auth are all finished fetching
            if (bHasFinishedLoading && bValidData) {
                const userGuessesData = userGuesses?.data?.data?.userGuesses;

                const dailyPropertiesData = dailyProperties?.data?.data?.properties;

                if (!isNil(userGuessesData) && !isNil(dailyPropertiesData)) {
                    const existingInputValues = userGuessesData.reduce((newValue, userGuess) => {
                        newValue[userGuess.property_id] = userGuess.guess;

                        return newValue;
                    }, []);

                    // We need to set which properties already have guess prices
                    const propertyGuessMapping = dailyPropertiesData.map((dailyProperty) => {
                        return !isEmpty(
                            userGuessesData.filter(
                                (guess) =>
                                    (guess?.property_id || guess?.id) ===
                                    (dailyProperty?.Property?.id || dailyProperty?.id)
                            )
                        );
                    }, []);

                    const lastAnsweredPropertyIndex = findLastIndex(
                        propertyGuessMapping,
                        (propertyGuess) => propertyGuess
                    );

                    setPropertyGuessMappings(propertyGuessMapping);

                    // Set the latest property index based on the last answered property index or the first property index (whichever is higher)
                    setLatestPropertyIndex(max([lastAnsweredPropertyIndex, 0]));

                    // If there are properties to play left, show the first unanswered property
                    const firstUnansweredPropertyIndex = propertyGuessMappings.indexOf(false);
                    const isLocked = get(arrProperties[firstUnansweredPropertyIndex], "is_locked", null);

                    if (
                        firstUnansweredPropertyIndex !== -1 &&
                        firstUnansweredPropertyIndex !== currentPropertyIndex &&
                        !isEmpty(arrProperties) &&
                        !isNil(isLocked) &&
                        !isLocked && // prevent setting on locked properties
                        !isInitialPropertySet
                    ) {
                        setProperty(
                            arrProperties[firstUnansweredPropertyIndex]?.Property ||
                                arrProperties[firstUnansweredPropertyIndex]
                        );
                        setGuessPrice("");
                        setInputDisable(false);
                        setCurrentPropertyIndex(firstUnansweredPropertyIndex);
                        setLatestPropertyIndex((prevIndex) => prevIndex + 1);
                        setIsInitialPropertySet(true); // to make clicking and staying into that property possible
                    } else {
                        const guestPlayArr = guestPlay ?? [];
                        // If there are no property left to guess OR no guesses for today yet, set to the first property
                        if (isEmpty(guestPlayArr.filter((d) => !d?.isChallengeGuess))) {
                            setProperty(
                                (dailyPropertiesData[0]?.Property || dailyPropertiesData[0]) ??
                                    INIT_PROPERTY_VALUES
                            );
                        }
                    }

                    setInputValues((prevInputValues) => ({
                        ...prevInputValues,
                        ...existingInputValues,
                    }));
                }
            }
        }

        showFirstUnansweredProperty();
    }, [
        dailyProperties.isFetching,
        userGuesses.isFetching,
        userGuesses?.data?.data?.userGuesses,
        isLoadingAuth,
        arrProperties,
    ]);
    /* eslint-disable react-hooks/exhaustive-deps */

    /**
     * An effect hook that fires a Google Analytics event when a guest user completes three plays.
     */
    useEffect(() => {
        function fireGuestCompletionGAEvent() {
            const guestPlayToday = getGuestPlaysToday();

            if (guestPlayToday.length === 3) {
                sendEvent("completion", {
                    isAuthenticated,
                    userArea: currentArea?.data?.name,
                    finalScore: totalScore + dailyScore,
                });
            }
        }

        fireGuestCompletionGAEvent();
    }, [guestPlay]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle the change event of the input field for guessing the price.
     * Updates the guessPrice state with the new value.
     *
     * @param {Object} e - The event object generated by the input field.
     */
    const handleGuessPriceChange = (e) => {
        setGuessPrice(e.target.value);
    };

    /**
     * Handles opening of guess hint via input
     */
    useEffect(() => {
        if (!isAuthenticated) {
            if (!!guessPrice) {
                const timeout = setTimeout(() => {
                    if (!hasOpenedGuessHint) {
                        setGuessTriggered(true);
                        setOpenGuessHint(true);
                    }
                }, OPEN_GUESS_HINT_TIMEOUT);

                return () => {
                    clearTimeout(timeout);
                };
            } else {
                setOpenGuessHint(false);
            }
        }
    }, [isAuthenticated, guessPrice, hasOpenedGuessHint]);

    /**
     * Sets the countdown and handles closing of guess hint after 10s
     */
    useEffect(() => {
        if (countdown !== -1 && guessTriggered) {
            if (countdown === 0) {
                setOpenGuessHint(false);
                setHasOpenedGuessHint(true);
            }

            const interval = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [countdown, guessTriggered]);

    useEffect(() => {
        if (property) {
            setCountdown(10);
            setOpenGuessHint(false);
            setHasOpenedGuessHint(false);
            setGuessTriggered(false);
        }
    }, [property]);

    /**
     * Handles the click event on a property indicator.
     *
     * @param {number} index - The index of the clicked property.
     */
    const handlePropertyClick = (index, unlockedPropIndex) => {
        if (index !== null) {
            setCurrentPropertyIndex(index);
            setProperty(arrProperties[index]?.Property || arrProperties[index]);
            setIsInitialPropertySet(true);

            // Clear the guess price
            setGuessPrice("");
        }

        if (unlockedPropIndex) {
            setUnlockedPropertyIndex(unlockedPropIndex);
            setShowUnlockPropertyToottip(true);
        }
    };

    /**
     * Scrolls the page to the top.
     */
    const scrollToTop = () => {
        // This prevents the page from scrolling down to where it was previously
        /* eslint-disable no-restricted-globals */
        if ("scrollRestoration" in history) {
            history.scrollRestoration = "manual";
        } /* eslint-disable no-restricted-globals */

        // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
            });
        }, 200);
    };

    const handleSendChallenge = async () => {
        await createChallengeLink(currentScoreId, perPropertyScore);
        sendEvent("send_challenge_button_click", {
            isAuthenticated,
            mlsId: property.mls_id ?? null,
        });
    };

    /**
     * Handle the event when moving to the next property.
     * Sets the necessary states and performs additional actions if applicable.
     *
     * @param {Object} e - The event object generated by the triggering element.
     */
    const handleNextProperty = async () => {
        let bSubmitClicked = !!inputDisable;

        // Add the guess price to the input values only if the submit button was clicked
        if (bSubmitClicked) {
            setInputValues((prevState) => ({
                ...prevState,
                [property.id]: unmaskValue(guessPrice),
            }));
        }

        // Reset the submit button clicked flag if there is a property guess data from the server
        // since we are automatically disabling the guess price input if there is property guess data
        if (!isNil(currentPropertyGuess?.data?.guess)) {
            bSubmitClicked = false;
        }

        setShowResultsModal(false);
        setInputDisable(false);

        let nextPropertyIndex = currentPropertyIndex + 1;

        if (hasSkippedProperties) {
            const skippedIndex = propertyGuessMappings.indexOf(false);

            // set to the first unanswered property
            nextPropertyIndex = skippedIndex === -1 ? nextPropertyIndex : skippedIndex;
        }

        // Prompt OneSignal subscription after 3rd property
        if (nextPropertyIndex === 2) {
            OneSignal.Slidedown.promptPush();
        }

        if (nextPropertyIndex < unLockedArrProperties.length) {
            // If the next property has no input value yet or the submit button is clicked, clear the guess price input
            if (!inputValues[arrProperties[nextPropertyIndex].id] || !bSubmitClicked) {
                setGuessPrice("");
            }

            // If this is not a previous property, update the latest property
            if (!isPreviousProperty) {
                setLatestPropertyIndex(nextPropertyIndex);
            }

            setProperty(arrProperties[nextPropertyIndex]?.Property || arrProperties[nextPropertyIndex]);
            setCurrentPropertyIndex(nextPropertyIndex);

            // Jump back to the top of the page
            scrollToTop();
        } else {
            setShowFinishedModal(true);
        }

        const plays = guestPlay ?? [];
        const practiceTotalScore = plays.reduce((total, currentValue) => total + currentValue.score, 0);
        const completedPracticeRound = plays.length === 3 ?? false;
        // we show practice result modal in lieu of finished modal, when user is guest and has completed the rounds
        if (!isAuthenticated && completedPracticeRound) {
            setShowPracticeFinalScore({ score: practiceTotalScore, completed: completedPracticeRound });
        }
    };

    /**
     * Handle the form submission event.
     * Performs various actions such as showing results, calculating scores, and updating the total score.
     */
    const handleSubmit = async () => {
        // Validate the user guess first
        if (!(await validateUserGuess())) {
            toast.error(TOAST_MESSAGES.INVALID_PROPERTY_GUESS, { id: "INVALID_PROPERTY_GUESS" });

            return;
        }

        // Remove the currency sign and separator from the masked input value
        const guessPriceVal = unmaskValue(guessPrice);

        // Parse the guess and real price to numbers
        const guess = parseInt(guessPriceVal === "" ? 0 : guessPriceVal);
        const realPrice = parseInt(property.price);

        // If the guess price is empty, set it to zero instead
        if (guessPriceVal === "") {
            setGuessPrice("0");
        }

        // Assign the result of calculateScore to a variable
        const result = getScoreFeedback(guess, realPrice);
        setResults(result);

        // Add the score for this guess to the total score
        dailyScore += result.score;
        addTotalScore(dailyScore);

        // Set OneSignal hasPlayed tag
        if (!hasPlayed) {
            OneSignal.User.addTag("hasPlayed", "true");
        }

        // Refetch user guesses every after submit
        setRefetchUserGuesses(true);

        // Set the final input value
        setInputValues((prevState) => ({
            ...prevState,
            [property.id]: guessPriceVal,
        }));

        // Only save the user guess and score to the database for registered users
        if (isAuthenticated) {
            userGuessScoreMutation.mutate(
                {
                    guess,
                    score: result.score,
                    area_id: userState?.current_area_id,
                },
                {
                    onSuccess: async (data) => {
                        const scoreId = data?.data?.score?.id ?? null;
                        // Show the results modal and disable the input controls
                        setCurrentScoreId(scoreId);
                        setShowResultsModal(true);
                        setInputDisable(true);

                        const userGuesses = await getUserGuessesPerPeriod({
                            user_id: userState?.id,
                        });

                        if (userGuesses?.data?.count?.total === 3) {
                            sendEvent("completion", {
                                isAuthenticated,
                                userArea: currentArea?.data?.name,
                                finalScore: totalScore + dailyScore,
                            });
                        }
                    },
                    onError: (error) => {
                        console.log(error);
                        // Ignore multi-clicks, it is guarded in the backend
                        toast.error(TOAST_MESSAGES.DUPLICATE_GUESS, { id: "DUPLICATE_GUESS" });
                        setInputDisable(true);
                    },
                }
            );
        } else {
            // Set the guest submission in the local storage only if it doesn't exist yet
            const filteredGuestPlay = filter(
                guestPlay,
                (guessScore) =>
                    guessScore.address === propertyAddress &&
                    guessScore.area_id === userState?.current_area_id
            );

            if (filteredGuestPlay.length === 0) {
                const updatedGuestPlay = [
                    ...(guestPlay ?? []),
                    {
                        guess,
                        id: property.id,
                        score: result.score,
                        address: propertyAddress,
                        created_at: new Date().toISOString(),
                        area_id: userState?.current_area_id,
                    },
                ];

                // Set the guest submission in the local storage
                setGuestPlay(updatedGuestPlay);
            }

            // Show the results modal and disable the input controls
            setShowResultsModal(true);
            setInputDisable(true);
        }
    };

    /**
     * Handles the closing of the results modal.
     * @param {boolean} [bNextProperty=false] - Indicates whether to handle the next property after closing the modal.
     */
    const handleResultsModalClose = (bNextProperty = false) => {
        setShowResultsModal(false);

        if (!bNextProperty) {
            setUserState({ ...userState, show_scrollability_tooltip: true });
        }

        // When user finished guessing all the properties,
        // show finished modal when closing result modal
        if (hasGuessedAllProperties) {
            if (guessesCount === config.MAXIMUM_GUESS) {
                setShowFinishedModal(true);
            } else {
                setShowSoldoutModal(true);
            }
        } else if (bNextProperty || hasSkippedProperties) {
            handleNextProperty();
        }
    };

    /**
     * Validates if the user's guess corresponds to the current daily property.
     *
     * @async
     * @returns {Promise<boolean>} A Promise that resolves to `true` if the user's guess is for the current property, otherwise `false`.
     */
    const validateUserGuess = async () => {
        const currentDailyProperties = await getDailyProperties(
            !isNil(userState?.id) && !isNull(userState?.id) ? userState?.id : "null",
            isAuthenticated
        );

        const isCurrentPropertyGuess =
            (currentDailyProperties.data?.properties ?? []).filter(
                (currentDailyProperty) =>
                    (currentDailyProperty?.Property?.id || currentDailyProperty?.id) === property.id
            ).length === 1;

        return isCurrentPropertyGuess;
    };

    /**
     * This function fetches user guesses for the current user in a specific area.
     */
    const fetchUserGuesses = useCallback(async () => {
        if (!refetchUserGuesses) {
            return;
        }

        if (userState?.id) {
            if (isAuthenticated) {
                // Get user guesses today
                const userGuesses = await getUserGuessesPerPeriod({
                    user_id: userState?.id,
                });
                // Set refetch to false
                setRefetchUserGuesses(false);
                setUserGuessesData(userGuesses?.data);

                // Set has played based on # of user guesses
                setHasPlayed(userGuesses?.data?.count?.total > 0);
            } else {
                // Set has played based on # of guest guesses
                setHasPlayed(getGuestPlaysToday()?.length > 0);
            }
        }
    }, [refetchUserGuesses, userState?.id, isAuthenticated, getGuestPlaysToday]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Checks if user has played a game today
     */
    useEffect(() => {
        void fetchUserGuesses();
    }, [fetchUserGuesses]);

    useMemo(() => {
        if (!dailyProperties.isLoading && !(dailyProperties.isFetching || isLoadingAuth)) {
            setPropertyGuessMappings(() => {
                return dailyProperties.data.data.properties.map((dailyProperty) => {
                    return inputValues.hasOwnProperty(dailyProperty?.Property?.id || dailyProperty?.id);
                }, []);
            });
        }
    }, [inputValues]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const inputValuesArr = Object.entries(inputValues);
        const dailyProps = dailyProperties?.data?.data?.properties ?? [];

        if (inputValuesArr?.length === 4) {
            setPropertyGuessMappings(() => {
                return dailyProps.map((dailyProperty) => {
                    return inputValues.hasOwnProperty(dailyProperty.id);
                }, []);
            });
        }
    }, [inputValues, setModalId, dailyProperties?.data?.data?.properties]);

    // Sets property guess mappings
    useEffect(() => {
        const inputValuesArr = Object.entries(inputValues);
        const dailyProps = dailyProperties?.data?.data?.properties ?? [];

        if (inputValuesArr?.length === 4) {
            setLatestPropertyIndex((prev) => prev + 1);
            setPropertyGuessMappings(() => {
                return dailyProps.map((dailyProperty) => {
                    return inputValues.hasOwnProperty(dailyProperty.id);
                }, []);
            });
        }
    }, [inputValues, setModalId, dailyProperties?.data?.data?.properties]);

    /**
     * Set input disable for guest users
     */
    useEffect(() => {
        if (!isAuthenticated) {
            const guessPropertyIds = userGuesses?.data?.data?.userGuesses?.map((guess) => guess?.property_id);

            // If user has guessed the property, hide input then show answer
            if (guessPropertyIds.includes(property.id)) {
                setInputDisable(true);
            }
        }
    }, [isAuthenticated, property, userGuesses?.data?.data]);
    const propertyIndex = arrProperties.findIndex((p) => p.id === property.id);

    return (
        <>
            {showLoader ? (
                <CircleLoadingProgress
                    finishLoading={() => setShowLoader(false)}
                    isLoading={dailyProperties.isFetching || isLoadingAuth}
                />
            ) : !isEmpty(arrProperties) ? (
                <div className="property-container" ref={bodyRef}>
                    <PropertyInfo property={property} arrProperties={arrProperties} />
                    {/* 
                        Had to put the Tooltip here to make the tooltip overlap with the PropertyInfo.
                        For some reason, the zIndex does not work as expected.
                        The data-tooltip-id can be located inside the ProgressIndicator component.
                    */}
                    {isAuthenticated ? (
                        <>
                            {userState?.show_scrollability_tooltip && (
                                <CustomTooltip
                                    id="scrollability-tooltip"
                                    contentString="Choose a property to send! Tap to select, drag to scroll"
                                    open={userState?.show_scrollability_tooltip}
                                    onClose={() =>
                                        setUserState({ ...userState, show_scrollability_tooltip: false })
                                    }
                                    autoCloseTime={15000}
                                    animate
                                />
                            )}
                            {showUnlockPropertyToottip && (
                                <CustomTooltip
                                    id={`unlock-property-tooltip-${unlockedPropertyIndex}`}
                                    contentString="Unlock extra guesses by winning challenges. Try sending one to a friend!"
                                    open={showUnlockPropertyToottip}
                                    onClose={() => setShowUnlockPropertyToottip(false)}
                                    autoCloseTime={5000}
                                    animate
                                />
                            )}
                        </>
                    ) : null}
                    <Sticky className="guess-input-sticky" mode="bottom" positionRecheckInterval={50}>
                        <div className="guess-input-container overflow-hidden px-3 py-3 guess-input-container-shadow">
                            <div
                                className={clsx(
                                    "flex-wrap d-flex align-items-center justify-content-between",
                                    `gap-${isScrollable ? 2 : 1}`
                                )}
                            >
                                <div
                                    className={clsx(
                                        "progress-indicator-container flex-grow-1 d-flex align-items-center position-relative",
                                        `gap-${isScrollable ? "1 scrollable" : 2} `
                                    )}
                                >
                                    <span>Properties:</span>
                                    <ProgressIndicator
                                        type="properties"
                                        onStepClick={handlePropertyClick}
                                        index={currentPropertyIndex}
                                        latestIndex={latestPropertyIndex}
                                        progressLength={progressLength}
                                        activeStepMapping={propertyGuessMappings}
                                        arrProperties={arrProperties}
                                    />
                                </div>

                                <TotalScore />
                            </div>

                            <div className="input-btn-container d-flex gap-2 w-100 mx-auto my-0 mt-2">
                                {/** Hints */}
                                {!isAuthenticated && !inputDisable && (
                                    <GuestHints
                                        countdown={countdown}
                                        open={
                                            openGuessHint &&
                                            guessParser(guessPrice) !== property.price &&
                                            !!guessParser(guessPrice)
                                        }
                                        guess={guessPrice}
                                        answer={property.price}
                                    />
                                )}
                                <div className="input px-0">
                                    <GuessPriceInput
                                        onClear={() => {
                                            setGuessPrice("");
                                        }}
                                        inputDisable={inputDisable}
                                        inputRef={inputRef}
                                        handleGuessPriceChange={handleGuessPriceChange}
                                        prices={{
                                            guess: unmaskValue(currentInputValue ?? ""),
                                            actual: property.price,
                                        }}
                                        score={perPropertyScore}
                                        hasGuessedAllProperties={hasGuessedAllProperties}
                                    />
                                </div>
                                <div className="btn p-0">
                                    {!isAuthenticated &&
                                    showNextListingButton &&
                                    currentPropertyIndex === arrProperties.length - 1 ? (
                                        <CustomButton
                                            className="gold-solid"
                                            handleClick={handleNextProperty}
                                            id="next-property-main"
                                            data-cy="next-property-button"
                                            text="See Results"
                                        />
                                    ) : (
                                        <CustomButton
                                            className={clsx(
                                                "d-flex align-items-center justify-content-center",
                                                inputDisable && isAuthenticated
                                                    ? "light-purple-solid"
                                                    : "gold-solid"
                                            )}
                                            handleClick={
                                                inputDisable
                                                    ? isAuthenticated
                                                        ? handleSendChallenge
                                                        : handleNextProperty
                                                    : handleSubmit
                                            }
                                            id="submit-guess"
                                            dataCy="submit-button"
                                            text={
                                                inputDisable
                                                    ? isAuthenticated
                                                        ? "Send Challenge"
                                                        : "Next"
                                                    : "Submit"
                                            }
                                            isLoading={userGuessScoreMutation.isLoading}
                                        />
                                    )}
                                </div>
                                {hasGuessedAllProperties && (
                                    <div className="done-btn p-0">
                                        <CustomButton
                                            id="done-btn"
                                            className="purple-outlined d-flex align-items-center justify-content-center"
                                            handleClick={() => setShowFinishedModal(true)}
                                            text="Done"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Sticky>
                    {showResultsModal && (
                        <ResultsModal
                            propertyIndex={propertyIndex}
                            totalProperties={unLockedPropertiesCount}
                            show={showResultsModal}
                            results={results}
                            guessPrice={unmaskValue(guessPrice ?? "")}
                            currentScoreId={currentScoreId}
                            realPrice={property.price}
                            mlsId={property?.mls_id || 0}
                            handleClose={handleResultsModalClose}
                        />
                    )}
                    {showFinishedModal && !showPracticeFinalScore.completed && (
                        <FinishedModal
                            show={showFinishedModal}
                            handleClose={() => {
                                setShowFinishedModal(false);
                                window.location.href = "/leaderboard";
                            }}
                            orderedScores={orderedScores}
                        />
                    )}
                    {showPracticeFinalScore.completed ? (
                        <PracticeResultsModal
                            show={showPracticeFinalScore.completed}
                            score={showPracticeFinalScore.score}
                            handleClose={() => {
                                setShowPracticeFinalScore({ score: 0, completed: false });
                                window.location.href = "/leaderboard";
                            }}
                        />
                    ) : null}
                    {showSoldoutModal ? (
                        <SoldoutModal
                            show={showSoldoutModal}
                            handleClose={(donefornow = true) => {
                                if (donefornow) {
                                    setShowFinishedModal(true);
                                } else {
                                    navigate("/daily-properties");
                                    setUserState({ ...userState, show_scrollability_tooltip: true });
                                }
                                setShowSoldoutModal(false);
                            }}
                            orderedScores={orderedScores}
                        />
                    ) : null}
                </div>
            ) : null}
        </>
    );
}
