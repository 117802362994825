import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "./property-image-swiper.scss";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default function PropertyImageSwiper(props) {
    const swiperElRef = useRef(null);
    const [propertySwiper, setPropertySwiper] = useState();
    const [loaded, setLoaded] = useState(false);

    // Set the swiper progress to 0 when the images are updated (switch property)
    // Fixes: https://app.asana.com/0/1204535088114623/1203873386482929/f
    useEffect(() => {
        if (propertySwiper && !propertySwiper.destroyed) {
            propertySwiper.setProgress(0);
            setTimeout(() => propertySwiper.autoplay?.start(), 2000);
            setLoaded(false);
        }
    }, [props.images]); // eslint-disable-line react-hooks/exhaustive-deps

    // Starts or stops swiper autoplay based on first image loading state
    useEffect(() => {
        if (propertySwiper && !propertySwiper.destroyed) {
            if (loaded) {
                let isAutoplayStopped = false;

                // Initialize the fancybox 
                Fancybox.bind("[data-fancybox]", {
                    on: {
                        // When the fancybox is opened, stop the autoplay
                        "Carousel.ready": (fancybox, slide) => {
                            isAutoplayStopped = true;

                            propertySwiper.autoplay.stop();
                        },
                        // When the fancybox is closing, stop the autoplay and slide to the last opened slide
                        shouldClose: (fancybox, slide) => {
                            propertySwiper.autoplay.stop();

                            propertySwiper.slideTo((fancybox.getSlide()).index);
                        },
                    },
                    Toolbar: {
                        enabled: false,
                    },
                    Thumbs: false
                });

                setTimeout(() => {
                    if (!isAutoplayStopped) {
                        propertySwiper.autoplay?.start();
                    }
                }, 2000);
            } else {
                propertySwiper.autoplay.stop();
            }
        }
    }, [loaded, propertySwiper]);

    return (
        <Swiper
            ref={swiperElRef}
            modules={[Autoplay, Navigation, Pagination, EffectFade]}
            autoplay={{
                delay: 2000,
                disableOnInteraction: true,
            }}
            loop
            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            pagination={{
                el: ".swiper-pagination",
                type: "fraction",
                renderFraction: function (currentClass, totalClass) {
                    if (props.images.length < 5) {
                        return `<div class="pagination-fraction-wrapper px-1 insufficient-image-text" data-cy="pagination">
                            <span><i>MLS rules require us to keep the rest of these photos top secret</i></span>
                        </div>`;
                    }

                    return `<div class="pagination-fraction-wrapper px-1" data-cy="pagination">
                                <span class="${currentClass}"></span> of <span class="${totalClass}"></span>
                            </div>`;
                },
            }}
            className="property-image-swiper"
            data-cy="property-image-swiper"
            speed="400"
            effect="fade"
            onSwiper={(swiper) => {
                setPropertySwiper(swiper);
            }}
        >
            {props.images.map((strImageUrl, intIndex) => (
                <SwiperSlide
                    key={"property-image-slide-" + intIndex}
                    data-cy="swiper-slide"
                    className="swiper-slide"
                >
                    <div className="image-container" data-fancybox data-src={strImageUrl}>
                        <img
                            onLoad={() => {
                                if (intIndex === 0) {
                                    setLoaded(true);
                                }
                            }}
                            src={strImageUrl}
                            alt={"property-image-slide-" + intIndex}
                        />
                    </div>
                </SwiperSlide>
            ))}
            <div className="swiper-button-prev" data-cy="swiper-button-prev"></div>
            <div className="swiper-button-next" data-cy="swiper-button-next"></div>
            <div className="swiper-pagination"></div>
        </Swiper>
    );
}
