import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { filter, findIndex, some, split, toUpper, isEmpty, isNil, trim, toLower } from "lodash";
import TextTruncate from "react-text-truncate";
import { Tooltip } from "react-tooltip";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

import CustomMap from "../custom-map/custom-map";
import PropertyImageSwiper from "../property-image-swiper/property-image-swiper";
import SalesInfo from "../../../pages/daily-properties/sales-info/sales-info";
import SimilarProperties from "../../../pages/daily-properties/similar-properties/similar-properties";

import { PROPERTY_TYPE } from "../../../lang/property-type.lang";
import { TOOLTIP_TEXT } from "../../../lang/tooltip-text.lang";
import { isZero } from "../../../helpers/helpers";

/**
 * Component for displaying property information.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.property - The property data.
 * @param {Array} props.arrProperties - The array of properties.
 * @returns {JSX.Element} Property information component.
 */
const PropertyInfo = ({ property, arrProperties }) => {
    const [showMore, setShowMore] = useState(false);
    const { isAuthenticated } = useAuth0();
    const propertyAddress = split(property.street_address, ",")[0]

    /**
     * Function to check and update property address if duplicates are found
     *
     * @param {Object} property - The property data.
     * @param {Array} arrProperties - The array of properties.
     * @returns {string} The unique property address.
     */
    const makeAddressUnique = (property, arrProperties) => {
        const normalizedStreetAddress = toLower(trim(propertyAddress));
        const normalizedNeighborhood = toLower(trim(property.neighborhood));

        if (!arrProperties) {
            return propertyAddress;
        }

        const otherProperties = filter(arrProperties, (p) => p.id !== property.id);
        const hasDuplicate = some(
            otherProperties,
            (p) => 
                split(toLower(trim(p.street_address)), ",")[0] === normalizedStreetAddress &&
                toLower(trim(p.neighborhood)) === normalizedNeighborhood
        );

        if (hasDuplicate) {
            const currentPropertyIndex = findIndex(arrProperties, { id: property.id });
            return `#${currentPropertyIndex + 1} ${propertyAddress}`;
        }

        return propertyAddress;
    };

    const uniquePropertyAddress = makeAddressUnique(property, arrProperties);

    const isCommonLotDimension =
        !isNil(property?.lot_dimensions) &&
        !isEmpty(property.lot_dimensions) &&
        toUpper(property.lot_dimensions) === "COMMON";

    const isUnknownLaundryFeature =
        !isNil(property?.laundry_features) &&
        !isEmpty(property.laundry_features) &&
        toUpper(property.laundry_features) === "UNKNOWN";

    return (
        <Fragment>
            <PropertyImageSwiper images={property.image_urls} />

            <div className="property-details property-name d-flex flex-column px-2">
                <div className="property-details-key">
                    <h2 id="address">
                        {uniquePropertyAddress} | {property.neighborhood}
                    </h2>
                    <div className="main-info-row px-0">
                        <div className="main-info-column bed-column">
                            <p>Beds</p>
                            <span id="bedrooms">{property.bedrooms}</span>
                        </div>
                        <div className="main-info-column bath-column">
                            <p>Baths</p>
                            <span id="bathrooms">{property.bathrooms}</span>
                        </div>
                        <div className="main-info-column sq-column">
                            <p>Sq. Ft</p>
                            <span
                                className={clsx(!isZero(property.square_footage) && "has-tooltip")}
                                id="square-footage"
                                data-tooltip-id="square-footage"
                            >
                                {isZero(property.square_footage) ? "Unknown" : property.square_footage}
                            </span>
                            {!isZero(property.square_footage) && (
                                <Tooltip
                                    id="square-footage"
                                    anchorSelect="[data-tooltip-id='square-footage']"
                                    content={TOOLTIP_TEXT.SQUARE_FOOTAGE}
                                    place="top"
                                />
                            )}
                        </div>
                        <div className="main-info-column type-column">
                            <p>Type</p>
                            <span id="property-type">
                                {PROPERTY_TYPE[property.property_type] || property.property_type}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="property-map-container px-0 px-sm-2">
                <CustomMap property={property} />
            </div>

            <div className="property-details px-3">
                <div className="property-details-key">
                    <div className="about-listing-row pb-3">
                        <div className="px-0">
                            <h3 className="mt-2">
                                <strong className="subtitle">About this listing</strong>
                            </h3>
                            {!showMore ? (
                                <TextTruncate
                                    line={3}
                                    element="p"
                                    truncateText="…"
                                    text={property.public_remarks}
                                    containerClassName="truncated-public-remarks-container"
                                    textTruncateChild={
                                        <button
                                            onClick={() => setShowMore(true)}
                                            className="show-more-btn mt-2"
                                        >
                                            Show More
                                        </button>
                                    }
                                />
                            ) : (
                                <p>
                                    <span className="public-remarks">{property.public_remarks}</span>{" "}
                                    <span>
                                        <button
                                            onClick={() => setShowMore(false)}
                                            className="show-more-btn mt-2"
                                        >
                                            Show less
                                        </button>
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>

                    <SimilarProperties property={property} isAuthenticated={isAuthenticated} />

                    <div id="additional-details">
                        <div className="pt-3 pb-4">
                            <h3 className="mb-2">
                                <strong className="subtitle">Additional Details</strong>
                            </h3>
                            <div className="details-item d-flex justify-content-between">
                                <span>Parking Spots:</span>
                                <p>{`${property.parking_spots} ${property.parking_type}`}</p>
                            </div>
                            <div className="details-item d-flex justify-content-between">
                                <span>Year Built:</span>
                                <p>{isZero(property.year_built) ? "Unknown" : property.year_built}</p>
                            </div>
                            <div className="details-item d-flex justify-content-between">
                                <span>Exterior Features:</span>
                                <p>{property.exterior_features}</p>
                            </div>
                            <div className="details-item d-flex justify-content-between">
                                <span>Interior Features:</span>
                                <p>{property.interior_features}</p>
                            </div>
                            <div className="details-item d-flex justify-content-between">
                                <span>Heating:</span>
                                <p>{property.heating}</p>
                            </div>
                            <div className="details-item d-flex justify-content-between">
                                <span>AC:</span>
                                <p>{property.cooling}</p>
                            </div>
                            <div className="details-item d-flex justify-content-between">
                                <span>Laundry Features:</span>
                                <p>
                                    <span
                                        className={clsx(isUnknownLaundryFeature && "has-tooltip")}
                                        data-tooltip-id="laundry-features"
                                    >
                                        {property.laundry_features}
                                    </span>
                                </p>
                                {isUnknownLaundryFeature && (
                                    <Tooltip
                                        id="laundry-features"
                                        anchorSelect="[data-tooltip-id='laundry-features']"
                                        content={TOOLTIP_TEXT.LAUNDRY_FEATURES}
                                        place="top"
                                    />
                                )}
                            </div>
                            <div className="details-item d-flex justify-content-between">
                                <span>Lot Dimensions:</span>
                                <p>
                                    <span
                                        className={clsx(isCommonLotDimension && "has-tooltip")}
                                        data-tooltip-id="lot-dimension"
                                    >
                                        {property.lot_dimensions}
                                    </span>
                                </p>
                                {isCommonLotDimension && (
                                    <Tooltip
                                        id="lot-dimension"
                                        anchorSelect="[data-tooltip-id='lot-dimension']"
                                        content={TOOLTIP_TEXT.LOT_DIMENSIONS}
                                        place="top-start"
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <SalesInfo property_id={property.id} />
                </div>
            </div>
        </Fragment>
    );
};

PropertyInfo.propTypes = {
    property: PropTypes.object.isRequired,
    arrProperties: PropTypes.array
};

export default PropertyInfo;
